<!-- RobotTxtEasterEgg.vue -->
<template>
  <div class="cmd-container">
    <div class="cmd-header">C:\Users\Neo\_</div>
    <transition-group name="fade" tag="div" class="cmd-content">
        <p v-for="(line, index) in shownLines" :key="index">
            <span v-if="line.prompt" class="cmd-prompt">C:\></span> {{ line.text }}
        </p>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
return {
  allLines: [
    { text: 'access_matrix.exe', prompt: true },
    { text: 'Accessing...', prompt: false },
    { text: 'Booting surprise module...', prompt: false },
    { text: 'Wait, you\'re not a robot!', prompt: false },
    { text: 'Looks like you\'ve stumbled into a digital playground.', prompt: false },
    { text: 'While you\'re here, ever pondered this:', prompt: false },
    { text: 'Why did the developer go broke?', prompt: false },
    { text: 'run_joke_answer.exe', prompt: true },
    { text: 'Because they used up all their cache! 😂', prompt: false },
    { text: 'Okay, I admit, bot humor needs an upgrade. Enjoy browsing the rest! :)', prompt: false }
  ],
  shownLines: []
}

  },
  mounted() {
    this.animateLines();
  },
  methods: {
    animateLines() {
      let index = 0;
      const interval = setInterval(() => {
        this.shownLines.push(this.allLines[index]);
        index++;
        if (index === this.allLines.length) {
          clearInterval(interval);
        }
      }, 1500); // speed of the "typing"
    }
  }
}
</script>

<style scoped>
body, html {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.cmd-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    color: #0f0;
    font-family: "Courier New", Courier, monospace;
    display: flex;
    flex-direction: column; 
    padding: 20px;
    z-index: 9999;
    max-width: 100%; 
    box-sizing: border-box;
    
}

.cmd-header {
    background-color: #0f0;
    color: #000;
    padding: 5px;
    font-weight: bold;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
}

.cmd-content {
    padding-top: 10px; 
    width: 100%;
    box-sizing: border-box;
}

.cmd-prompt {
    font-weight: bold;
}

/* Transition & Animation Styles */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.cmd-content p {
  animation: type 1.5s steps(40, end);
  white-space: nowrap;
  overflow: hidden;
  width: 14ch;
  animation-fill-mode: forwards;
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
